import React from "react"
import { LinkExternalIcon } from "@primer/octicons-react"

type AppHighlightProps = {
  imageSrc: string
  title: string
  description: string
  url?: string
  buttonText?: string
}

export default function AppHighlight(props: AppHighlightProps) {
  let fontColorString = "rgb(90, 90, 90)"

  return (
    <div className="col-lg-4 col-md-6 mt-4 mb-4">
      <div className="row">
        <div className="col">
          <div className="text-center">
            <img src={props.imageSrc} style={{ height: "140px" }} />
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col">
          <h3
            style={{
              textAlign: "center",
              fontSize: 30,
              color: fontColorString,
            }}
          >
            {props.title}
          </h3>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <p style={{ fontSize: "14", color: fontColorString }}>
            {props.description}
          </p>
        </div>
      </div>
      {props.url && props.buttonText && (
        <div className="row">
          <div className="col text-center">
            <a
              className="btn btn-light btn-link"
              href={props.url}
              target="_app-highlight"
            >
              {props.buttonText}&nbsp;
              <LinkExternalIcon size="small" />
            </a>
          </div>
        </div>
      )}
    </div>
  )
}
