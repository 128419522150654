import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AppHighlight from "../components/app-highlight"
import xamarinImage from "../images/xamarin_icon.png"
import reactIcon from "../images/react_icon.png"
import angularIcon from "../images/angular_icon.svg"
import swiftUI from "../images/swiftui-icon.png"
import azureLogo from "../images/azure_logo.png"
import netCore from "../images/netcore_icon.svg"
import bootstrapIcon from "../images/bootstrap_icon.png"
import kotlinLogo from "../images/kotlin_logo.png"
import gitLogo from "../images/git_logo.png"
import sqlIcon from "../images/sql_icon.png"
import reactRouterIcon from "../images/react_router_icon.png"
import reduxIcon from "../images/redux.svg"
import chromeIcon from "../images/chrome-logo.svg"
import { Link } from "gatsby"

export default function Technologies() {
  let fontColorString = "rgb(90, 90, 90)"

  return (
    <Layout>
      <SEO
        title="Technologies"
        description="Recent and favorite technologies used by John VanSickle"
      />

      <div className="row">
        <AppHighlight imageSrc={reactIcon} title="React JS" description="" />

        <AppHighlight imageSrc={angularIcon} title="Angular" description="" />

        <AppHighlight imageSrc={netCore} title="ASP.NET Core" description="" />

        <AppHighlight imageSrc={swiftUI} title="SwiftUI" description="" />

        <AppHighlight
          imageSrc={xamarinImage}
          title="Xamarin.Forms"
          description=""
        />

        <AppHighlight
          imageSrc={azureLogo}
          title="Microsoft Azure"
          description=""
        />

        <AppHighlight
          imageSrc={bootstrapIcon}
          title="Bootstrap"
          description=""
        />

        <AppHighlight
          imageSrc={reactRouterIcon}
          title="React Router"
          description=""
        />

        <AppHighlight imageSrc={reduxIcon} title="Redux" description="" />

        <AppHighlight
          imageSrc={chromeIcon}
          title="Chrome Extension"
          description=""
        />

        <AppHighlight imageSrc={sqlIcon} title="SQL" description="" />

        <AppHighlight imageSrc={kotlinLogo} title="Kotlin" description="" />
      </div>

      <div className="row">
        <div className="col">
          <h3
            style={{
              fontSize: 30,
              color: fontColorString,
            }}
          >
            And many more.
          </h3>

          <p style={{ fontSize: "14", color: fontColorString }}>
            See my <Link to="/resume">resume</Link>.
          </p>
        </div>
      </div>
    </Layout>
  )
}
